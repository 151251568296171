import React from "react";
import "../styles/Header.css";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";

function Header() {
  const [showNav, setShowNav] = useState(true);

  const toggleNav = () => {
    setShowNav(!showNav);
  };

  return (
    <div className="header-container">
      <header>
        <div className="logo-container">
          
        </div>
        <p
        style={{
          cursor: "pointer",
        }}
        onClick={() => {
          window.location.href = "/";
        }}
        >Infinity Eight Data Solutions</p>
        <button className="menu-icon" onClick={toggleNav}>
          <MenuIcon />
        </button>

        <div className={showNav ? "nav-container hidden" : "nav-container"}>
          <ul>
            <li>
              <a href="#webdev">Web Development</a>
            </li>
            <li>
              <a href="#mobile">Mobile Apps</a>
            </li>
            <li>
              <a href="#webApp">Web Apps / SAAS</a>
            </li>
            <li>
              <a href="#digitalMarketing">Digital Marketing</a>
            </li>
            <li>
              <a href="#hr">HR Solutions</a>
            </li>
            <li>
              <a href="#dataScience">Data Science and Analytics</a>
            </li>

            <button
            onClick={() => {
              window.location.href = "https://forms.gle/9qSkkAkrJSPPVrYt7";
            }}>
              
              Inquire Now</button>
          </ul>
        </div>
      </header>
    </div>
  );
}

export default Header;

import React from "react";
import "../styles/DigitalMarketing.css";
import Digital from "../assets/digital.png";

function DigitalMarketing() {
  return (
    <div className="digital-container" id="digitalMarketing">
      <div className="digital">
        <div className="left-container">
          <h3>Digital Marketing</h3>
          <p>
            Navigate the ever-evolving digital landscape with our strategic
            digital marketing services. From search enginge optimization to
            social media management, we harness the power of online channels to
            amplify your brand's visibility and drive tangible results.
          </p>
          <p>
            <a href="/">Learn More...</a>
          </p>
        </div>
        <div className="right-container">
          <img src={Digital} alt="" />
        </div>
      </div>
    </div>
  );
}

export default DigitalMarketing;

import React from "react";
import "../styles/HRSolutions.css";
import HR from "../assets/hr.png";

function HRSolutions() {
  return (
    <div className="hr-container" id="hr">
      <div className="hr">
        <div className="left-container">
          <img src={HR} alt="" />
        </div>
        <div className="right-container">
          <h3>HR Solutions</h3>
          <p>
            Your workforce, your most valuable asset. Our comprehensive HR
            Solutions optimize your human resources management, from talent
            acquisition to performance evaluation.{" "}
          </p>
          <p>
            <a href="/">Learn More...</a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default HRSolutions;

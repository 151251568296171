import React from "react";
import "../styles/Hero.css";


function Hero() {
  return (
    <div className="hero-container">
      <div className="hero">
        <div className="left-container">
          <h3>Unlimited Possibilities</h3>
          <p>We gather data everyday, so you wont have to</p>
          <span>Sign up now, It's FREE!</span>
          <div className="cont-signup">
            <input type="text" placeholder="Enter your email address" />
            <button
            onClick={() => {
              window.location.href = "https://forms.gle/9qSkkAkrJSPPVrYt7";
            }}
            >Sign up</button>
          </div>
        </div>
        <div className="right-container"></div>
      </div>
    </div>
  );
}

export default Hero;

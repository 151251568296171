import React from "react";
import "../styles/Webdev.css";
import WebdevImg from "../assets/webdev-girl.png";
import WebDevImgComp from '../assets/webdev.png'

function Webdev() {
  return (
    <div className="webdev-container" id='webdev'>
      <div className="webdev">
        <div className="left-container">
          <img src={WebDevImgComp} alt="" />
        </div>
        <div className="right-container">
            <div className="upper-content">
                <h3>Web Development Services</h3>
                <p>Unlock the power of the digital realm with our expert web development services. From sleek and responsive designs to seamless user experiences. We transform your vision into a captivating online presence. </p>
                <p><a href="/">Learn more...</a></p>
            </div>
            <div className="lower-content">
                <div className="lower-content-left">
                    <h4>Did you know?</h4>
                    <p>People search for products or services every millisecond!</p>
                    <p>So you must be:</p>
                    <span>
                        <ul>
                            <li>Searcheable and Easy to Find</li>
                            <li>Your Website should look good to make them stay</li>
                            <li>Your Website explains your product and services well</li>
                            <li>Your Website should drive ACTION!</li>
                        </ul>
                    </span>
                </div>
                <div className="lower-content-right">
                    <img src={WebdevImg} alt="" 
                       
                    />
                </div>
            </div>
        </div>
      </div>
    </div>
  );
}

export default Webdev;

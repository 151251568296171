import React from "react";
import "../styles/TechStacks.css";
import Aws from "../assets/aws-logo.png";
import FirebaseLogo from "../assets/firebase-logo.png";
import MongoDb from "../assets/mongodb-logo.png";

function TechStacks() {
  return (
    <div className="tech-container">
      <div className="techstack">
        <h3>Digital Services All Businesses Need</h3>
        <div className="logo-cont">
            <div className="logo">
                <p>Powered By</p>
               
            </div>
            <div className="logo">
                <img src={Aws} alt="" 
                style={{
                    width: "90px",
                }}
                
                />
            </div>
            <div className="logo">
                <img src={FirebaseLogo} alt="" 
                     style={{
                        width: "200px",
                    }}/>
            </div>
            <div className="logo">
                <img src={MongoDb} alt="" 
                     style={{
                        width: "200px",
                    }}/>
            </div>
        </div>
      </div>
    </div>
  );
}

export default TechStacks;

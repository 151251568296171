import React from "react";
import "../styles/WebApps.css";
import Laptop from "../assets/laptop.png";

function WebApps() {
  return (
    <div className="webApps-container" id='webApp'>
      <div className="webApps">
        <div className="left-container">
          <img src={Laptop} alt="" />
        </div>
        <div className="right-container">
          <h3>Web Apps and SAAS</h3>
          <p>
            Empower your business with cutting-edge web apps and Software as a
            Service (Saas) solutions tailored to your unique needs. Streamline
            operations, enhance productivity, and provide exceptional value to
            your clients through intuitive and scalable software.{" "}
          </p>
          <p>
            <a href="/">Learn More..</a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default WebApps;

import React from "react";
import "../styles/Footer.css";

function Footer() {
  return (
    <div className="footer-container">
      <div className="footer">
        <div className="left-container">
          <h3>Infinity Eight Data Solutions Inc. </h3>
          <p>5th floor Phinma Plaza</p>
          <p>Rockwell Center, Makati City</p>
          <p>Tel: 02 8396 6612</p>
          <p>
            <a href="mailto:inquire@infinity8data.com">inquire@infinity8data.com</a></p>
        </div>
        <div className="right-container">
          <div className="card">
            <h3>Company</h3>
            <p>About Us</p>
            <p>How it works</p>
            <p>Documentation</p>
          </div>
          <div className="card">
            <h3>Quick Links</h3>
            <p>Pricing</p>
            <p>Resources</p>
          </div>
          <div className="card">
            <h3>Product Updates</h3>
            <p>Get Product Updates</p>
            <input type="email" placeholder="Enter Email Address" />
            <button
              onClick={() => {
                window.location.href = "https://forms.gle/9qSkkAkrJSPPVrYt7";
              }}
            
            
            >Subscribe</button>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="social-media">
          <p>Follow us on Social Media</p>
          <i className="fab fa-facebook-f"></i>
          <i className="fab fa-twitter"></i>
          <i className="fab fa-instagram"></i>
          <i className="fab fa-linkedin-in"></i>
        </div>

        <p>Privacy Policy</p>
        <p>© 2023 Infinity Eight Data Solutions Inc. All rights reserved.</p>
      </div>
    </div>
  );
}

export default Footer;

import React from "react";
import "../styles/MobileApps.css";
import MobileBoy from "../assets/mobile-boy.png";
import MobileAppsImg from "../assets/mobileAppDev.png";

function MobileApps() {
  return (
    <div className="mobileApps-container" id='mobile'>
      <div className="mobileApps">
        <div className="left-container">
          <div className="upper-content">
            <h3>Mobile Apps Development</h3>
            <h3>(IOS and Android)</h3>
            <p>Your business at your user's fingertips.</p>
            <p>
              Our mobile app development team create's stunning, user-friendly
              applications for both IOS and Android platforms.{" "}
            </p>
            <p>
              <a href="/">Learn more...</a>
            </p>
          </div>
          <div className="lower-content">
            <div className="lower-content-left">
              <h4>Did you know?</h4>
              <p>Direct Customer Engagement</p>

              <span>
                Mobile apps provide a direct and personalized channel to engage
                with customers, allowing you to send push notifications,
                updates, and promotions directly to their mobile devices.
              </span>
            </div>
            <div className="lower-content-right">
            <img src={MobileBoy} alt="" 
         
            />

            </div>
          </div>
        </div>
        <div className="right-container">
            <img src={MobileAppsImg} alt="" />
            
        </div>
      </div>
    </div>
  );
}

export default MobileApps;

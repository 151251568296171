import React from 'react'
import '../styles/DataScience.css'
import Data from '../assets/data.png'

function DataScience() {
  return (
    <div className='dataScience-container'id='dataScience'>
        <div className="dataScience">
            <div className="left-container">
                <h3>Data Science and Analytics</h3>
                <p>Unleash the potential of your data with our data science and analytics expertise. Transform raw information into actionable insights that drive informed decision-making. </p>
                <p><a href="/">Learn More...</a></p>
            </div>
            <div className="right-container">
                <img src={Data} alt="" />
            </div>
        </div>


    </div>
  )
}

export default DataScience
import React from 'react'
import Header from '../components/Header'
import Hero from '../components/Hero'
import TechStacks from '../components/TechStacks'
import Webdev from '../components/Webdev'
import MobileApps from '../components/MobileApps'
import WebApps from '../components/WebApps'
import DigitalMarketing  from '../components/DigitalMarketing'
import HRSolutions from '../components/HRSolutions'
import DataScience from '../components/DataScience'
import Footer from '../components/Footer'

function Landing() {
  return (
    <div>
      <Header />
      <Hero />
      <TechStacks />
      <Webdev />
      <MobileApps />
      <WebApps />
      <DigitalMarketing />
      <HRSolutions />
      <DataScience />
      <Footer />
    </div>
  )
}

export default Landing